import React, { useContext, useDebugValue } from 'react';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';

import useAccesses, { useAccessMutator, useGenerationIssues } from 'lib/hooks/useAccess';
import { useTemplateContent } from 'lib/hooks/useTemplate';
import utils from 'lib/utils';
import inputs from 'lib/inputs';
import AccessManager from 'lib/AccessManager';

import { MConfirm } from 'components/shared/Alerts';
import BannerSticky from 'components/lib/BannerSticky';
import Constants from 'components/Constants';
import { UserContext } from 'components/UserContext';

function AccessIssuesBanner({ allDynamicContent, attachedTemplate, currentTemplate }) {
  const { data: accessesByTemplateId } = useAccesses('template');
  const { data: templateContent } = useTemplateContent(currentTemplate?.id, currentTemplate?.deleted);
  const { data: attachedTemplateContent } = useTemplateContent(attachedTemplate?.id, attachedTemplate?.deleted);

  const generationIssues = useGenerationIssues([currentTemplate?.id, attachedTemplate?.id]);
  const { update: updateAccesses } = useAccessMutator();

  const { items: itemsToGrant } = useItemsToGrant(
    generationIssues,
    currentTemplate,
    templateContent,
    attachedTemplate,
    attachedTemplateContent,
    allDynamicContent,
  );

  const templateAccessors = accessesByTemplateId?.[currentTemplate.id] ?? [];
  const templateGroupAccessors = templateAccessors.filter((access) => access.accessor_type === 'group');
  const templateUserAccessors = templateAccessors.filter(
    (access) => access.accessor_type === 'user' && access.permission !== 'owner',
  );

  const groupAccessorIdsWithPermissionIssues = new Set();
  const userAccessorIdsWithPermissionIssues = new Set();
  for (const itemType in generationIssues[currentTemplate.id]) {
    for (const accessorsWithPermissionIssues of Object.values(generationIssues[currentTemplate.id][itemType])) {
      for (const accessor of accessorsWithPermissionIssues) {
        if (accessor.type === 'group') {
          groupAccessorIdsWithPermissionIssues.add(accessor.id);
        } else {
          userAccessorIdsWithPermissionIssues.add(accessor.id);
        }
      }
    }
  }

  if (!groupAccessorIdsWithPermissionIssues.size && !userAccessorIdsWithPermissionIssues.size) {
    return null;
  }

  let content = 'This template is shared with';
  if (templateGroupAccessors.length) {
    content += ` ${Pluralize('group', templateGroupAccessors.length, true)}`;
    if (templateUserAccessors.length) {
      content += ' and';
    }
  }
  if (templateUserAccessors.length) {
    content += ` ${Pluralize('user', templateUserAccessors.length, true)}`;
  }
  content += ', but';
  if (groupAccessorIdsWithPermissionIssues.size) {
    content += ` ${Pluralize('group', groupAccessorIdsWithPermissionIssues.size, true)}`;
    if (userAccessorIdsWithPermissionIssues.size) {
      content += ' and';
    }
  }
  if (userAccessorIdsWithPermissionIssues.size) {
    content += ` ${Pluralize('user', userAccessorIdsWithPermissionIssues.size, true)}`;
  }
  content += ` ${Pluralize(
    'is',
    userAccessorIdsWithPermissionIssues.size + groupAccessorIdsWithPermissionIssues.size,
  )} missing permissions to generate.`;

  const handleAdd = () => {
    MConfirm(
      'Updating access to relevant assets',
      'The users will be granted Read access to all data sources, dynamic content and inputs that are necessary to generate this template.',
      'warning',
      (confirmed) => {
        if (confirmed) {
          const updateItemAccesses = (item) => {
            // like [{ accessor_type: "type", accessor_id: 0 }, .... ]
            const accessors = Object.entries(item.accessors).flatMap(([accessorType, accessorIds]) => {
              const typeAccessors = [];
              accessorIds.forEach((accessorId) => {
                typeAccessors.push({ accessor_type: accessorType, accessor_id: accessorId });
              });
              return typeAccessors;
            });
            return updateAccesses(item.type, [item.id], Constants.PERMISSIONS.read.value, accessors);
          };

          // First grant access to data sources since we can't grant access to DC unless they can also access the
          // queried data source.
          const updateDataSourceAccesses = itemsToGrant
            .filter((item) => item.type === Constants.ItemTypes.DATA_SOURCE)
            .map(updateItemAccesses);
          Promise.all(updateDataSourceAccesses).then(() => {
            itemsToGrant
              .filter((item) => item.type === Constants.ItemTypes.DYNAMIC_CONTENT)
              .forEach(updateItemAccesses);
          });
        }
      },
      'Update access',
    );
  };

  return (
    <BannerSticky
      theme="error"
      content={content}
      actionText={itemsToGrant?.length > 0 ? 'Grant access' : null}
      actionOnClick={handleAdd}
    />
  );
}

AccessIssuesBanner.propTypes = {
  allDynamicContent: PropTypes.object,
  attachedTemplate: PropTypes.object,
  currentTemplate: PropTypes.object,
};

export default AccessIssuesBanner;

/** Find the data sources and dynamic content which need access granted. Only return those items that can the user
 * can actually grant access to (i.e. they have edit permissions on that item)
 */
function useItemsToGrant(
  generationIssues,
  currentTemplate,
  templateContent,
  attachedTemplate,
  attachedTemplateContent,
  allDynamicContent,
) {
  const accessorsToAddByDataSourceId = {};
  const accessorsToAddByDynamicContentId = {};
  const templateTags = utils.getDynamicContentTags(currentTemplate, templateContent, allDynamicContent).getTagNodes();
  const attachedTemplateTags = utils
    .getDynamicContentTags(attachedTemplate, attachedTemplateContent, allDynamicContent)
    .getTagNodes();
  const allContent = templateTags.concat(attachedTemplateTags).map((tag) => tag.matchingContent);
  const templateInputs = inputs.getAllInputs(templateTags, allDynamicContent, currentTemplate, templateContent, null);
  const attachedTemplateInputs = attachedTemplateTags.length
    ? inputs.getAllInputs(attachedTemplateTags, allDynamicContent, attachedTemplate, attachedTemplateContent)
    : {};
  const allInputs = Object.values(templateInputs).concat(Object.values(attachedTemplateInputs));
  for (const itemType in generationIssues[currentTemplate.id]) {
    for (const itemId in generationIssues[currentTemplate.id][itemType]) {
      if (itemType === Constants.ItemTypes.DYNAMIC_CONTENT) {
        const item = allContent.find((content) => content?.id === parseInt(itemId));
        if (item) {
          const dataSourceId = item.query_obj?.data_source?.id;
          if (!(itemId in accessorsToAddByDynamicContentId)) {
            accessorsToAddByDynamicContentId[itemId] = { group: new Set(), user: new Set() };
          }
          if (dataSourceId > 0 && !(dataSourceId in accessorsToAddByDataSourceId)) {
            accessorsToAddByDataSourceId[dataSourceId] = { group: new Set(), user: new Set() };
          }
          for (const accessor of generationIssues[currentTemplate.id][itemType][itemId]) {
            accessorsToAddByDynamicContentId[itemId][accessor.type].add(accessor.id);
            if (dataSourceId in accessorsToAddByDataSourceId) {
              accessorsToAddByDataSourceId[dataSourceId][accessor.type].add(accessor.id);
            }
          }
        }
      } else {
        const dataSourceId = allInputs.find((input) => input.id === parseInt(itemId))?.query_obj?.data_source?.id;
        if (dataSourceId && !(dataSourceId in accessorsToAddByDataSourceId)) {
          accessorsToAddByDataSourceId[dataSourceId] = { group: new Set(), user: new Set() };
        }
        for (const accessor of generationIssues[currentTemplate.id][itemType][itemId]) {
          if (dataSourceId in accessorsToAddByDataSourceId) {
            accessorsToAddByDataSourceId[dataSourceId][accessor.type].add(accessor.id);
          }
        }
      }
    }
  }

  const { isPending: isDataSourceAccessPending, canEdit: canEditDataSources } = useCanEdit(
    Constants.ItemTypes.DATA_SOURCE,
    Object.keys(accessorsToAddByDataSourceId),
  );
  const { isPending: isContentAccessPending, canEdit: canEditContent } = useCanEdit(
    Constants.ItemTypes.DYNAMIC_CONTENT,
    Object.keys(accessorsToAddByDynamicContentId),
  );

  let items = null;
  if (!isDataSourceAccessPending && !isContentAccessPending) {
    items = [];
    canEditDataSources.forEach((itemId) => {
      items.push({
        type: Constants.ItemTypes.DATA_SOURCE,
        id: itemId,
        accessors: accessorsToAddByDataSourceId[itemId],
      });
    });
    canEditContent.forEach((itemId) => {
      items.push({
        type: Constants.ItemTypes.DYNAMIC_CONTENT,
        id: itemId,
        accessors: accessorsToAddByDynamicContentId[itemId],
      });
    });
  }
  useDebugValue(items);
  return { isPending: isDataSourceAccessPending || isContentAccessPending, items };
}

/** Check to see if the user can edit the given items. Return the IDs of those which the user can edit. */
function useCanEdit(itemType, itemIds) {
  const { isPending, data } = useAccesses(itemType, itemIds);
  const { user } = useContext(UserContext);

  let canEdit = null;
  if (!isPending && data) {
    canEdit = [];
    itemIds.forEach((itemId) => {
      const accessManager = new AccessManager(itemId, data, user);
      if (accessManager.can(Constants.PERMISSIONS.edit.value)) {
        canEdit.push(itemId);
      }
    });
  }
  useDebugValue(canEdit);
  return {
    isPending,
    canEdit,
  };
}
